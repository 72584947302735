import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <b>ᴆ.net</b> new decentralized social network
      </header>
    </div>
  );
}

export default App;
